i {
  font-family: 'entypo';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
}

.social {
	font-size: 22px;
}

.icon-social {
  font-family: 'entypo-social';
  font-size: 22px;
  display: block;
  position: relative;
}