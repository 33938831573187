// ------------------------------
// PAGINATION
// ------------------------------

.pagination {
  display: block;
  margin: 0 0 4em 0;
}

  .pagination__page-number {
    margin: 0;
    font-size: .8em;
    color: $gray-dark;
  }

  .pagination__newer {
    margin-right: 1em;
  }

  .pagination__older {
    margin-left: 1em;
  }