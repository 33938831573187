*:focus {
  outline: none;
}

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"] {
  width: 240px;
  padding: 1em 1em;
  background: #FFF;
  border: 1px solid $gray-light;
  border-radius: $border-radius;
  font-size: .9em;
  color: $gray-darker;

  &:focus {
    border-color: $secondary-color;
  }

  @include placeholder {
    color: $gray;
  }

}