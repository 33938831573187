
.pwa-box{
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;

    li{
        width: 200px;
        height: 350px;
        padding: 10px;
        text-align: center;

        img{
            width: 200px;
            height: 320px;
        }
        span{
            line-height: 30px;
        }
    }
}