// ------------------------------
// SINGLE READING-NOTES LAYOUT
// ------------------------------

.reading-notes{
    padding: 10px 0 10px;
    ul{
      width: 100%;
      margin: 0;
      padding: 0;

      li{
        display: flex;
        flex-flow: row;

        padding: 18px 0;
        border-bottom: 1px solid #ddd;

        .book-poster{
          
          img{
            width: 140px;
          }
        }
  
        .book-meta{
          flex:1;
          padding-left: 20px;

          .excerpt{
            padding-top: 10px;
          }
        }
      }

      li:last-child{
        border-bottom:none;
      }
    }
}